import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { nanoid } from "nanoid";

const Pagination = ({ pageContext }) => {
  let path = false,
    numberOfPages,
    humanPageNumber,
    pageNav = [],
    i;
  if (!pageContext.numberOfPages)
    return <React.Fragment key={nanoid()}></React.Fragment>;
  numberOfPages = parseInt(pageContext.numberOfPages);
  humanPageNumber = parseInt(pageContext.humanPageNumber);

  //console.log(pageContext);
  //console.log(pageContext.numberOfPages);

  if (pageContext.nextPagePath) {
    path = pageContext.nextPagePath.split("/");
  } else {
    path = pageContext.previousPagePath.split("/");
  }

  if (
    path[1] === "category" ||
    path[1] === "categories" ||
    path[1] === "tags" ||
    path[1] === "tag" ||
    path[1] === "author"
  ) {
    path = path[1] + "/" + path[2];
  } else {
    path = path[1];
  }

  if (path) {
    let pageSlug,
      pages = [],
      className;

    for (i = 1; i <= numberOfPages; i++) {
      if (i === 1) {
        pageSlug = `/${path}`;
      } else {
        pageSlug = `/${path}/${i}`;
      }

      if (humanPageNumber === i) {
        className = "pagination-link is-current";
      } else {
        className = "pagination-link";
      }

      pages.push(
        <React.Fragment key={nanoid()}>
          <li>
            <Link
              to={`${pageSlug}#posts`}
              className={className}
              aria-label={`Page ${i}`}
              title={`Page ${i}`}
            >
              {i}
            </Link>
          </li>
        </React.Fragment>
      );
    }

    // always start with first link

    pageNav.push(pages[0]);

    if (numberOfPages >= 5) {
      // 5 or more links
      if (humanPageNumber === 1) {
        // first display

        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </React.Fragment>
        );
        pageNav.push(pages[1]);
        pageNav.push(pages[2]);
        pageNav.push(pages[3]);
        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </React.Fragment>
        );
        pageNav.push(pages.pop());
      } else if (
        humanPageNumber + 1 === numberOfPages ||
        humanPageNumber === numberOfPages
      ) {
        // second last display

        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </React.Fragment>
        );
        pageNav.push(pages[numberOfPages - 4]);
        pageNav.push(pages[numberOfPages - 3]);
        pageNav.push(pages[numberOfPages - 2]);

        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip; </span>
            </li>
          </React.Fragment>
        );
        pageNav.push(pages.pop());
      } else {
        // calculate display

        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </React.Fragment>
        );
        let showing = 0;

        if (humanPageNumber - 2 >= 1) {
          pageNav.push(pages[humanPageNumber - 2]);
          showing++;
        }
        if (humanPageNumber - 1 >= 1) {
          pageNav.push(pages[humanPageNumber - 1]);
          showing++;
        }
        if (humanPageNumber >= 1) {
          pageNav.push(pages[humanPageNumber]);
          showing++;
        }
        if (showing < 3) {
          pageNav.push(pages[humanPageNumber + 1]);
          showing++;
        }

        pageNav.push(
          <React.Fragment key={nanoid()}>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </React.Fragment>
        );
        pageNav.push(pages.pop());
      }
    } else {
      pageNav = pages;
    }
  }

  if (pageContext && pageContext.numberOfPages) {
    return (
      <React.Fragment key={nanoid()}>
        <a name="posts"> </a>
        <br clear="all" />

        <div className="content">
          <nav className="pagination" role="navigation" aria-label="pagination">
            {pageContext.previousPagePath ? (
              <Link
                className="pagination-previous"
                to={pageContext.previousPagePath}
              >
                Previous
              </Link>
            ) : null}
            {pageContext.nextPagePath ? (
              <Link className="pagination-next" to={pageContext.nextPagePath}>
                Next
              </Link>
            ) : null}

            {pageNav && pageNav.length ? (
              <React.Fragment key={nanoid()}>
                <ul className="pagination-list m-0">{pageNav}</ul>
              </React.Fragment>
            ) : null}
          </nav>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

Pagination.propTypes = {
  pageContext: PropTypes.object,
};

export default Pagination;
