import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { nanoid } from "nanoid";
import Layout from "../components/Layout";

import FullWidthImage from "../components/FullWidthImage";
import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import BlogList from "../components/BlogList";
import Pagination from "../components/Pagination";
import SocialIcons from "../components/SocialIcons";

import DisplayContent from "../components/DisplayContent";
import DisplayTitleWithImage from "../components/DisplayTitleWithImage";

import { HTMLContent } from "../components/Content";
import { kebabCase } from "lodash";
const pluralize = require("pluralize");

// eslint-disable-next-line
export const AuthorPostTemplate = ({
  title,
  subtitle,
  footer,
  body,
  summary,
  images,
  options,
  author,
  creationDate,
  posts,
  authors,
  pageContext,
  helmet,
  social,
  previewTitle,
  previewDate,
  previewSummary,
  breadcrumbs,
  layout,
  videos,
  related,
  header,
}) => {
  let templateName = "author-post",
    heroVideo,
    heroImage,
    smallImage,
    footerImage,
    renderMode = "renderDivDateSummaryImage",
    renderSize = 4,
    paginationPosition = "below",
    renderWeight = "normal";

  if (header && header.layout && header.layout.render) {
    if (header.layout.render.authors) {
      renderMode = header.layout.render.authors;
    }
    if (header.layout.render.size) {
      renderSize = header.layout.render.size;
    }

    if (header.layout.render.weight) {
      renderWeight = header.layout.render.weight;
    }
    if (header.layout.render.position) {
      paginationPosition = header.layout.render.position;
    }
    layout = header.layout;
  }

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
  }

  if (videos && videos.hero) heroVideo = videos.hero;
  if (!layout.position) layout.position = "below";

  const showTitle = options.showTitle;
  const showTitlesSwapped = options.showTitlesSwapped;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  // build categproes for this author
  // build tags for this author

  let tags = [],
    cats = [],
    tagsSorted = [],
    tagsPopular = [],
    catsSorted = [],
    catsPopular = [];

  posts.forEach((child) => {
    cats[child.node.frontmatter.category]
      ? cats[child.node.frontmatter.category]++
      : (cats[child.node.frontmatter.category] = 1);

    child.node.frontmatter.tags.forEach((tag) => {
      tags[tag] ? tags[tag]++ : (tags[tag] = 1);
    });
  });

  for (const [key] of Object.entries(cats)) {
    catsPopular.push(key);
  }
  for (const [key] of Object.entries(tags)) {
    tagsPopular.push(key);
  }

  catsPopular = catsPopular.slice(0, 5);
  tagsPopular = tagsPopular.slice(0, 5);

  tags = Object.keys(tags).sort();
  cats = Object.keys(cats).sort();

  // eslint-disable-next-line
  for (const [key, value] of Object.entries(cats)) {
    catsSorted.push(value);
  }

  catsPopular = catsPopular.map((cat) => (
    <React.Fragment key={nanoid()}>
      <Link title={cat} className="cat-join" to={`/category/${kebabCase(cat)}`}>
        <>{cat}</>
      </Link>
    </React.Fragment>
  ));

  catsSorted = catsSorted.map((cat) => (
    <React.Fragment key={nanoid()}>
      <Link title={cat} className="cat-join" to={`/category/${kebabCase(cat)}`}>
        <>{cat}</>
      </Link>
    </React.Fragment>
  ));

  // eslint-disable-next-line
  for (const [key, value] of Object.entries(tags)) {
    tagsSorted.push(value);
  }
  tagsPopular = tagsPopular.map((tag) => (
    <React.Fragment key={nanoid()}>
      <Link title={tag} className="tag-join" to={`/tag/${kebabCase(tag)}`}>
        <>{tag}</>
      </Link>
    </React.Fragment>
  ));
  tagsSorted = tagsSorted.map((tag) => (
    <React.Fragment key={nanoid()}>
      <Link title={tag} className="tag-join" to={`/tag/${kebabCase(tag)}`}>
        <>{tag}</>
      </Link>
    </React.Fragment>
  ));

  if (!author && previewTitle) {
    author = "[Not Set]";
  }

  if (!related) {
    related = [];
    related[0] = {};
    related[0].url = false;
  }

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroVideo={heroVideo}
        heroImage={heroImage}
        title={author || previewTitle}
        subtitle="Author"
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={showTitlesSwapped}
      />

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <DisplayTitleWithImage
              smallImage={smallImage}
              title={previewTitle ? previewTitle : author}
              showDisplayContentHeader={showDisplayContentHeader}
              parent="Authors"
              parentSlug="/authors"
              grandparent="Blog"
              grandparentSlug="/blog"
              header={header}
              imageSlug={related[0].url || false}
            />

            <DisplayContent
              templateName={templateName}
              previewContent={previewSummary}
              body={
                <>
                  <HTMLContent content={body} />
                  {related && related.length && related[0].title ? (
                    <>
                      <h3 className="is-5 mb-0 pb-0">Related Links:</h3>
                      <ul>
                        {related.map((entry) => {
                          return (
                            <li key={nanoid()}>
                              {entry.url ? (
                                <>
                                  <a
                                    href={entry.url}
                                    target="external"
                                    title={entry.title}
                                  >
                                    {entry.title}
                                  </a>
                                </>
                              ) : (
                                <React.Fragment key={nanoid()}>
                                  {entry.title}
                                </React.Fragment>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : null}
                  {social ? (
                    <>
                      <h3 className="is-5 mb-0 pb-0">
                        Follow{" "}
                        {previewTitle ? <>{previewTitle}</> : <>{author}</>}:
                      </h3>
                      <br />
                      <SocialIcons
                        socialIconClass="is-inline social-icon has-text-black pt-0 pl-0"
                        socialClass="pl-0 pt-0 mb-0 pb-0"
                        social={social}
                        target="external"
                      />
                      <br />
                    </>
                  ) : null}
                  {catsSorted && catsSorted.length ? (
                    <>
                      <DisplayContentHeader
                        title={pluralize("Category", catsSorted.length)}
                        weight="semibold"
                        showColon={true}
                        size="4"
                      />

                      <p>
                        {author} has categorized their{" "}
                        {pluralize("post", posts.length)} with the following{" "}
                        {pluralize("category", catsSorted.length)}:{" "}
                        {andJoin(catsSorted)}
                      </p>
                      {catsSorted.length > 5 ? (
                        <>
                          <p>
                            The most frequently used categories are:{" "}
                            {andJoin(catsPopular)}
                          </p>
                        </>
                      ) : null}
                      <br />
                    </>
                  ) : null}
                  {tagsSorted && tagsSorted.length ? (
                    <>
                      <DisplayContentHeader
                        title={pluralize("Tag", tagsSorted.length)}
                        weight="semibold"
                        showColon={true}
                        size="4"
                      />

                      <p>
                        {author} has tagged {pluralize("post", posts.length)}{" "}
                        with the following tags: {andJoin(tagsSorted)}
                      </p>
                      {tagsSorted.length > 5 && posts.length > 1 ? (
                        <>
                          <p>
                            Their most common tags are: {andJoin(tagsPopular)}
                          </p>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              }
              layout={layout}
              element={
                <>
                  <BlogList
                    posts={posts}
                    authors={authors}
                    title={`Blog ${pluralize("Post", posts.length)}:`}
                    titleMode="h3.5"
                    emptyMessage="This author has not yet contributed."
                    renderMode={renderMode}
                    renderSize={renderSize}
                    renderWeight={renderWeight}
                    layout={layout}
                  />
                </>
              }
              pagination={<Pagination pageContext={pageContext} />}
              paginationPosition={paginationPosition}
              pages={pageContext.numberOfPages}
            />
          </div>
        </div>
      </section>
      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

AuthorPostTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    published: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  author: PropTypes.string,
  social: PropTypes.object,
  related: PropTypes.array,
  renderMode: PropTypes.string,
};

const AuthorPost = ({ data, pageContext }) => {
  let { markdownRemark: post } = data;
  let { allMarkdownRemark: posts } = data;
  let { defaultAuthor } = data;
  let { authors } = data;
  let { header } = data;

  authors = authors.nodes;
  posts = posts.edges;

  let imageSrc;
  console.log(post.frontmatter.images);
  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  return (
    <Layout>
      <AuthorPostTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images || defaultAuthor.frontmatter.images}
        options={post.frontmatter.options}
        body={post.html}
        summary={post.frontmatter.summary}
        author={post.frontmatter.name || post.frontmatter.author}
        posts={posts}
        authors={authors}
        pageContext={pageContext}
        related={post.frontmatter.related}
        helmet={
          <Seo
            title={`Author > ${
              post.frontmatter.name
                ? post.frontmatter.name
                : post.frontmatter.title
            }`}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        social={post.frontmatter.social}
        header={header.frontmatter}
        creationDate={post.frontmatter.creationDate}
      />
    </Layout>
  );
};

AuthorPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AuthorPost;

export const authorPostQuery = graphql`
  query AuthorPost($id: String!, $name: String!, $skip: Int!, $limit: Int!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        name
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitlesSwapped
          showDisplayContentHeader
          showTitle
        }
        creationDate
        summary
        social {
          facebook
          twitter
          instagram
          youtube
          google
          linkedin
          github
          reddit
          tiktok
          podcast
          rss
          email
          pinterest
          snapchat
          yelp
          flickr
          twitch
          discord
        }
        related {
          title
          url
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    defaultAuthor: markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "author-page" } } }
    ) {
      html
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
        }
        summary
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___creationDate] }
      filter: {
        frontmatter: {
          advanced: { templateKey: { eq: "blog-post" } }
          author: { eq: $name }
        }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            titles {
              subtitle
            }
            images {
              hero {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
            tags
            advanced {
              templateKey
            }
            summary
            creationDate
            author
            options {
              showTitle
            }
            category
            flags {
              featured
            }
          }
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        layout {
          columns
          render {
            size
            authors
            position
            separator
            weight
          }
        }
        breadcrumbs {
          positionClass
          separatorClass
          position
          showDate
          showCurrent
        }
      }
    }
  }
`;

function andJoin(array) {
  let last = array.pop();
  let working = [];

  if (array.length) {
    working = array.map((item) => {
      return (
        <React.Fragment key={nanoid()}>
          <span className="tag-join">{item}</span>,{" "}
        </React.Fragment>
      );
    });

    working.push(
      <>
        and{" "}
        <React.Fragment key={nanoid()}>
          <span className="tag-join">{last}</span>.{" "}
        </React.Fragment>
      </>
    );
  } else {
    working.push(
      <>
        <React.Fragment key={nanoid()}>
          <span className="tag-join">{last}</span>.{" "}
        </React.Fragment>
      </>
    );
  }

  return working;
}
